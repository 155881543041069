import React from "react"
import {graphql} from "gatsby";
import ReactMarkdown from "react-markdown";
import {Container} from 'reactstrap'

import Layout from "../components/layout"
import PageTitle from '../components/pageTitle'
import SEO from "../components/seo"
import {LangContext} from "../components/lurraldebusWrapper";


const PagoDeInfraccionesPage = ({ data }) => (
  <LangContext.Consumer>
    { context => (
      <Layout>
        <SEO title={context.translate(data, 'pagoDeInfracciones', 'titulo')} />
        <Container className="py-5  mb-5">
          <PageTitle title={context.translate(data, 'pagoDeInfracciones', 'titulo')}/>
          <h5 className="text-center font-weight-bold pb-3 pt-4">{context.translate(data, 'pagoDeInfracciones', 'encabezado')}</h5>
          <ReactMarkdown source={context.translate(data, 'pagoDeInfracciones', 'texto')} className="text-muted"/>
          <img
            src={context.translate(data, 'pagoDeInfracciones', 'banner').publicURL}
            alt={context.translate(data, 'pagoDeInfracciones', 'titulo')}
            className="w-100 my-5"
          />
        </Container>
      </Layout>
    )}
  </LangContext.Consumer>
)

export default PagoDeInfraccionesPage

export const pageQuery = graphql`  
  query {
    pagoDeInfracciones: strapiPagoDeInfracciones {
      titulo
      encabezado
      texto
      banner {
        publicURL
      }
    }
    pagoDeInfracciones_eus: strapiPagoDeInfraccionesEus {
      titulo
      encabezado
      texto
      banner {
        publicURL
      }
    }
  }
`